import { CallType, PatientType } from 'enums';
import { hasFacesheet } from './valid.flag';

export const isCar = state => +state.serviceTypes.Curbside === +state.serviceId;

export const acceptedCarTrip = state =>
  isCar(state.service) && (state.requests.request.isActive || state.requests.request.isCompleted);

export const shouldDisplayHospitalToHospitalConfirmation = () =>
  !!window.ambulnzConfig.constants.HOSPITAL_TO_HOSPITAL_CONFIRMATION;

const isNotEmergencyCallType = service => +service.callTypeId !== window.ambulnzConfig.callTypes.EMERGENCY;

const hospitalToHospital = ({ fromSelected, toSelected }) => {
  const { emsFacilityTypes } = window.ambulnzConfig;

  return fromSelected.type === emsFacilityTypes.HOSPITAL && toSelected.type === emsFacilityTypes.HOSPITAL;
};

export const showHospitalToHospitalWarning = ({ location, service, requests }) =>
  window.ambulnzConfig.userSettings.isBillingEnabled &&
  shouldDisplayHospitalToHospitalConfirmation() &&
  hospitalToHospital(location) &&
  !requests.careProvidedAtReceivingFacility &&
  isNotEmergencyCallType(service);

export const hasCareProvidedAtDesc = requests =>
  requests.careProvidedAtReceivingFacility && requests.careProvidedAtReceivingFacilityDescription.length > 0;

export const careProvidedAtValid = requests =>
  !requests.careProvidedAtReceivingFacility || hasCareProvidedAtDesc(requests);

export const isBariatricValid = state =>
  hasFacesheet(state) || !state.service.bariatric || !!(state.infobox.patientWeight && state.infobox.patientHeight);

export const hasTransportReasons = state =>
  state.service.callTypeId !== CallType.DEFAULT ||
  (state.service.transportReasons && state.service.transportReasons.length > 0);

export const isSinglePrimaryCareSelected = state => {
  const pcpEquipmentIds = state.service.equipment.filter(e => e.name.includes('PCP')).map(eq => eq.id);
  return (
    state.service.callTypeId !== CallType.DEFAULT ||
    !pcpEquipmentIds.some(id => state.service.therapistEquipmentIds.includes(id)) ||
    (pcpEquipmentIds.some(id => state.service.therapistEquipmentIds.includes(id)) &&
      state.service.transportReasons.length === 1)
  );
};

export const nonMedicalTransportTypes = ({ service: { serviceTypeIds, serviceTypeDictionary } }) =>
  serviceTypeIds.some(
    type =>
      type === serviceTypeDictionary.Curbside.id ||
      type === serviceTypeDictionary.Livery.id ||
      type === serviceTypeDictionary.WAV.id,
  );

export const isWeightHeightRequired = state => {
  if (state.requests.isNonTransport) return false;

  if (nonMedicalTransportTypes(state)) return false;

  if (state.service.bariatric) return true;

  const { patientTypeId, patientTypes } = state.service;
  const nicuPatientType = (patientTypes || []).find(patientType => patientType.name === PatientType.NICU.toLowerCase());
  if (nicuPatientType && nicuPatientType.id === patientTypeId) return false;

  const { callTypeId, callTypeOptions } = state.service;
  const callTypeOption = (callTypeOptions || []).find(option => option.key === callTypeId);
  return callTypeOption && callTypeOption.weightHeightRequired;
};
