import ApiService from './api.service';
import localConfig from '../config.json';

async function loadCfgs(config, filter) {
  const rFilter = filter || {
    fields: ['id', 'name', 'value', 'dataType'],
  };
  const url = `${config.tenantedRestApiUrl}/Cfgs/?filter=${JSON.stringify(rFilter)}`;
  let response = await ApiService.get(url);
  response = response.json();
  return response;
}

export async function getCfgs(config, filter) {
  let constants = await loadCfgs(config, filter);
  if (!constants) return null;
  if (!Array.isArray(constants)) return null;

  constants = constants.map(c => {
    if (c.value === 'TRUE' || c.value === 'true') {
      c.value = true;
    } else if (c.value === 'FALSE' || c.value === 'false') {
      c.value = false;
    }

    return c;
  });

  return constants.reduce((obj, item) => Object.assign(obj, { [item.name]: item.value }), {});
}

export function updateTenantedBaseUrls(account) {
  window.ambulnzConfig.tenantedRestApiV2Url = `${window.ambulnzConfig.restApi}/v2/Accounts/${account.id}`;
  window.ambulnzConfig.tenantedRestApiUrl = `${window.ambulnzConfig.restApi}/v1/Accounts/${account.id}`;
  window.ambulnzConfig.tenantedOrderServiceUrl = `${window.ambulnzConfig.orderServiceUrl}/accounts/${account.id}`;
  window.ambulnzConfig.tenantedNotificationServiceUrl = `${
    window.ambulnzConfig.notificationsApi || ''
  }/notification/v1/accounts/${account.id}`;
}

export async function init() {
  if (process.env.NODE_ENV === 'development') {
    return localConfig;
  }

  try {
    const resp = await ApiService.get('/config.json');
    return resp.json();
  } catch (error) {
    return `Error: ${error}`;
  }
  // untenanted base uri
}
